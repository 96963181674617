import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "./layout/GlobalStyles"
import { ModalBlock } from "./Modal"
import { modalText } from "./modalText"
import { Button } from "./layout/StyledComponents"

const StyledGuidingPhilosophy = styled.div`
  background: ${colors.primary2};
  padding: 58px 0em 70px;
  margin: -1px 0em;
  h2,
  p {
    color: ${colors.white};
    margin: 0;
  }
  button {
    margin-top: 32px;
  }
  h2 {
    margin-bottom: 0.5em;
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 55px;
  }
  p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    padding: 128px 0em;
    .flex-container {
      display: flex;
      padding: 0em;
      justify-content: space-between;
    }
    h1 {
      padding: 0em;
    }

    p {
      max-width: 678px;

      margin: 0px 92.6px 0px 0px;
    }
  }
`

export const GuidingPhilosophy = () => {
  return (
    <StyledGuidingPhilosophy>
      <div className="container">
        <div className="flex-container">
          <h2>Guiding Philosophy</h2>
          <div>
            <p>
              We are a 501(c) 3 faith-based organization that favors learning
              from lived experiences of those we work with over paternalistic
              models. We support children with learning disadvantages to read.
              Motivate youth and young adults to lead and aid families in
              meeting their basic needs. We find those on the ground doing good
              work in the most challenging conditions and trust them.
            </p>
            <ModalBlock modalText={modalText.sdg.text} color="yellow">
              <Button color="yellow">See How</Button>
            </ModalBlock>
          </div>
        </div>
        {/* <Button color="yellow">See How</Button> */}
      </div>
    </StyledGuidingPhilosophy>
  )
}
