import React, { useContext } from "react"
import { GlobalStyles } from "../components/layout/GlobalStyles"
import { Nav } from "../components/nav/Nav"
import { Footer } from "../components/Footer"
import { siteContext } from "./SiteContextProvider"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { device } from "../components/layout/GlobalStyles"

const Container = styled.div`
  @media ${device.laptop} {
    position: relative;
    background: white;
    top: ${({ alertActive }) => (alertActive ? "16px" : "0px")};
    margin-top: ${({ alertActive }) => (alertActive ? "0" : "16pxpx")};
  }
`

export const AltLayout = ({ children }) => {
  const { setDesktopActive } = useContext(siteContext)
  let alert = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { active: { eq: true } }
          fields: { sourceName: { eq: "alert-banner" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              active
              title
              date
              alert_link
              prompts {
                blurb
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Nav />
      <GlobalStyles />
      <div onMouseEnter={() => setDesktopActive(false)}>{children}</div>
    </>
  )
}
