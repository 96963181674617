import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"

const StyledPurposeHero = styled.div`
  height: 78vh;
  padding: 1em;

  .main-image {
    z-index: -1;
    height: 85vh;
    position: absolute;
    top: 0em;
    width: 170%;
    right: 0em;
  }
  h1 {
    padding-top: 50px;
    margin-bottom: 0em;
    width: 280px;
    font-size: 60px;
    line-height: 1;
    font-weight: 300;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
  }
  p {
    font-family: ${fonts.primary};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} and (orientation: portrait) {
    height: 90vh;
    padding: 1em;
    .main-image {
      z-index: -1;
      height: 94vh;
      position: absolute;
      top: 0em;
      width: 170%;
      right: 0em;
    }
    h1 {
      padding: 100px 0em 0em 40px;
      margin-bottom: 0em;
      width: 390px;
      font-size: 80px;
      line-height: 1;
      font-weight: 400;
      color: ${colors.primary2};
      font-family: ${fonts.secondary};
    }
    p {
      padding-left: 50px;
      width: 350px;
      font-weight: 400;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    height: calc(100vh - 214px);
    padding: 0em;
    .main-image {
      height: 100vh;
      width: 100%;
    }
    h1 {
      font-size: 80px;
      margin-top: 214px;
      padding: 0em;
      width: 389px;
    }
    p {
      font-weight: 400;
      max-width: 350px;
    }
  }
`

export const PurposeHero = () => {
  return (
    <StyledPurposeHero className="container">
      <StaticImage
        className="main-image"
        src="../images/purpose-hero-two.jpeg"
        alt="hands cupping sun"
        placeholder="none"
        quality={100}
      />
      <h1>“I Am Because We Are”</h1>
      <p>Our work reflects the African Philosophy of Ubuntu.</p>
    </StyledPurposeHero>
  )
}
