import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./panels/Panel-Container"

const StyledVision = styled.section`
  background: ${colors.secondary102};
  padding: 46px 0px 72px;
  margin: -1px 0em 0px;
  @media ${device.tablet} and (orientation: portrait) {
    padding: 90px 0px 90px;
    z-index: 3;
    div:last-of-type {
      z-index: 3;
      img {
        z-index: 3;
        width: 544.9px;
      }
    }
  }
  @media ${device.tablet} {
    margin: -40px 0px;
    height: 450px;
    div:last-of-type {
      position: relative;
      top: 1.5em;
      img {
        width: 644.9px;
      }
    }
  }
`

const Text = styled.div`
  h3 {
    font-weight: 800;
    font-size: 60px;
    margin: 0em;
    font-family: ${fonts.secondary};
    color: ${colors.secondary203};
  }
  p {
    color: ${colors.white};
  }
  p:first-of-type {
    font-size: 30px;
    font-weight: 900;
    margin: 0em;
    letter-spacing: -0.94px;
    line-height: 36px;
    font-weight: 900;
  }
  p:last-of-type {
    margin-bottom: 40px;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 32px;
  }
  @media ${device.tablet} and (orientation: landscape) {
    width: 470px;

    margin-bottom: 100px;
    p:last-of-type {
      width: 480px;
    }
  }
`

export const Vision = () => {
  return (
    <div style={{ zIndex: "-1" }}>
      <StyledVision>
        <PanelContainer>
          <Text>
            <h3>Vision</h3>
            <p>An All-Inclusive Vision</p>
            <p>
              To live in a world where we all - everyone, everywhere - belong
              and experience the fullness of love.
            </p>
          </Text>
          <StaticImage
            src="../images/panels/vision-collage.png"
            placeholder="none"
            quality={100}
            alt="collage of African tribe, school children, and girl being fed"
          />
        </PanelContainer>
      </StyledVision>
    </div>
  )
}
